import React, { FC } from "react"
import tw, { styled } from "twin.macro"
import Image from "../components/image"

interface Props {
  data: any
}

const Card: FC<Props> = ({
  data: {
    category,
    date,
    description,
    // tags,
    title,
    banner: { base },
  },
}) => {
  return (
    <div css={[tw`max-w-xs rounded overflow-hidden shadow-lg my-2`]}>
      <Image src={base} alt="test" />
      <h1>{title}</h1>
      <h1>{category}</h1>
      <h1>{date}</h1>
      <h1>{description}</h1>
      <h1>{title}</h1>
    </div>
  )
}

export default Card

// const Container = styled.div`
//   ${tw`max-w-xs rounded overflow-hidden shadow-lg my-2`}
// `
// <Img fixed={data.file.childImageSharp.fixed} />
// <Img fixed={props.img} />

