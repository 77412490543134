import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Card from "../components/card"

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              id
              slug
              frontmatter {
                category
                date(formatString: "")
                description
                tags
                title
                banner {
                  base
                }
              }
            }
          }
        }
      }
    `
  )
  const query = data.allMdx.edges
  console.log(JSON.stringify(data))
  return (
    <Layout>
      {query.map(({ node: { id, slug, frontmatter: data } }) => (
        <Link key={id} to={slug}>
          <Card data={data} />
        </Link>
      ))}
    </Layout>
  )
}
export default IndexPage
